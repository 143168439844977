import type { LineItemType, Product } from 'productSelection/types/products';
import { addLineItem } from 'shared/hooks/orders/AddProductService';
import { BUSINESS_ACCOUNT_MODAL_IS_COMPLETED } from 'shared/infra/cookies';
import logger from 'shared/services/logger';
import type { Product as TrackProduct } from 'shared/services/tracker/types';
import type { CatalogState } from 'shared/store/catalog/types';
import type { OrderLineItem } from 'shared/store/order/types';

export const BA_FIXED_QUANTITY = 1;

export type BusinessAccountProduct = {
  product: Product;
  localizedName: string;
  contentfulId: string;
  code: string;
  lineItemType: LineItemType;
};

export async function addBusinessAccount(
  orderId: string,
  businessAccountProduct: BusinessAccountProduct,
  catalog: CatalogState,
): Promise<void> {
  try {
    const { contentfulId } = businessAccountProduct;
    const { formattedAmount, trackingId } = catalog[contentfulId];
    const addLineItemReq = {
      orderId,
      code: businessAccountProduct.code,
      quantity: BA_FIXED_QUANTITY,
      localizedName: businessAccountProduct.localizedName,
      reference: businessAccountProduct.contentfulId,
      formattedTotalAmount: formattedAmount,
      trackingId,
      lineItemType: businessAccountProduct.lineItemType,
      price: formattedAmount,
    };

    await addLineItem(addLineItemReq);
  } catch (err) {
    logger.error(err);
  }
}

export function removeBusinessAccountProductsFromCart(
  lineItems: OrderLineItem[],
  products: Product[],
): OrderLineItem[] {
  return lineItems.filter((lineItem) => {
    const isBAProduct = products.find(
      (product) => lineItem.code === product.businessAccount?.code,
    );

    return !isBAProduct;
  });
}
export function isBusinessAccountInCart(
  lineItems: OrderLineItem[],
  products: Product[],
): boolean {
  const businessAccountProduct = lineItems.find((lineItem) =>
    products.find((product) => lineItem.code === product.businessAccount?.code),
  );

  return !!businessAccountProduct;
}

export function makeBusinessAccountProduct(
  products: Product[],
  lineItems: OrderLineItem[],
): BusinessAccountProduct {
  const productInOrder = getProductForOrder(products, lineItems);

  const cfBAbySku: Record<Product['code'], Product> = products.reduce(
    (current, product: Product) =>
      product.businessAccount
        ? { ...current, [product.code]: product }
        : current,
    {},
  );

  const baProducts = productInOrder
    .filter((product: Product) => cfBAbySku[product.code])
    .map((product: Product): BusinessAccountProduct => {
      const { businessAccount } = cfBAbySku[product.code];

      return {
        product,
        localizedName: businessAccount.name,
        contentfulId: businessAccount.id,
        code: businessAccount.code,
        lineItemType: 'sku',
      };
    });

  return baProducts[0];
}

export function makeBusinessAccountProductForTracking(
  baProduct: BusinessAccountProduct,
  catalog: CatalogState,
): TrackProduct {
  return {
    id: baProduct.contentfulId,
    trackingId: catalog[baProduct.contentfulId]?.trackingId,
    price: catalog[baProduct.contentfulId]?.formattedAmount,
    quantity: BA_FIXED_QUANTITY,
  };
}

export function isBAModalCompleted(): boolean {
  return localStorage.getItem(BUSINESS_ACCOUNT_MODAL_IS_COMPLETED) === 'true';
}

export const ensureBAModalIsCompleted = (): void => {
  localStorage.setItem(BUSINESS_ACCOUNT_MODAL_IS_COMPLETED, 'true');
};

function getProductForOrder(
  products: Product[],
  productsInCart: OrderLineItem[],
): Product[] {
  return products.filter(
    (product: Product) =>
      !!productsInCart.find(
        (cartProduct: OrderLineItem) => cartProduct.code === product.code,
      ),
  );
}
