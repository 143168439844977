import type { Order } from '@commercelayer/sdk';
import { isAxiosError } from 'axios';
import { useCallback } from 'react';

import { CLAYER_ORDER_ID } from 'shared/infra/cookies';
import logger from 'shared/services/logger';
import isServer from 'shared/utils/is-server';

import { useGetOrder } from './useGetOrder';
import { useResetOrderState } from './useResetOrderState';

type UseLoadLocalOrderResult = (
  isPlacingStatusAllowed?: boolean,
) => Promise<Order>;

const getLocalOrderID = (): string | null => {
  try {
    return localStorage.getItem(CLAYER_ORDER_ID);
  } catch (err) {
    // ignore if localStorage is not available.
    if (!isServer) {
      logger.error(err, 'Unable to get local order id');
    }
    return null;
  }
};

export const shouldResetState = (
  order: Order,
  isPlacingStatusAllowed = false,
): boolean =>
  order.status === 'placing' &&
  order.payment_method.reference === 'tokenized_sepa' &&
  !isPlacingStatusAllowed;

export const useLoadLocalOrder = (): UseLoadLocalOrderResult => {
  const getOrder = useGetOrder();
  const resetOrderState = useResetOrderState();

  const loadLocalOrder = useCallback(
    async (isPlacingStatusAllowed?: boolean): Promise<Order> => {
      const localOrderId = getLocalOrderID();

      if (!localOrderId) {
        return null;
      }

      try {
        const order = await getOrder(localOrderId);

        if (shouldResetState(order, isPlacingStatusAllowed)) {
          resetOrderState();
          return null;
        }

        return order;
      } catch (err: unknown) {
        // 401 can happen when an order with the wrong status (e.g. paid) is loaded.
        // if the status is not 401, something else went wrong so we log it.
        if (isAxiosError(err) && err.status !== 401) {
          logger.error(
            err,
            `Error loading order with id: ${localOrderId}, status: ${err.status}`,
          );
        }

        resetOrderState();

        return null;
      }
    },
    [getOrder, resetOrderState],
  );

  return loadLocalOrder;
};
